import React from 'react';
import styled from 'styled-components';
import Header from '../components/Header';
import SocialsHome from '../components/SocialsHome';
import AboutHome from '../components/AboutHome';
import GalleryHome from '../components/GalleryHome';
import { device } from '../lib/device';
import HomeData from '../hooks/getHomeContent';
import Seo from '../components/Seo';
import '../style/app.scss';

const HomeGrid = styled.div`
	padding: 0 80px;
	display: grid;
	grid-template-columns: auto 1fr 2fr;
	gap: 80px;
	min-height: 800px;
	height: calc(95vh - 73px);
	place-content: center;
	@media ${device.desktopHhd} {
		gap: 40px;
	}
	@media ${device.desktop} {
		grid-template-columns: 1fr;
		grid-auto-flow: dense;
		height: auto;
		padding: 0 15px;
	}
`;

const Home = () => {
	const data = HomeData();
	return (
		<>
			<Seo seo={data.seo} />
			<Header />
			<HomeGrid>
				<SocialsHome />
				<AboutHome
					title={data.acf.mainTitle}
					subtitle={data.acf.subtitle}
					description={data.acf.description}
					heroImage={data.acf.heroImage}
				/>
				<GalleryHome links={data.acf.galleryLinks} />
			</HomeGrid>
		</>
	);
};

export default Home;
