import React from 'react';
import '../style/app.scss';
import styled from 'styled-components';
import ScrollAnimation from 'react-animate-on-scroll';
import { device } from '../lib/device';
import IconInstagram from './svg/IconInstagram';
import IconFacebook from './svg/IconFacebook';
import IconTwitter from './svg/IconTwitter';

const SocialsHomeWrap = styled.div`
	position: relative;
	/* margin-right: 120px; */
	/* margin-left: -50px; */
	align-self: center;
	@media ${device.desktop} {
		margin: 0;
	}
	@media ${device.desktop} {
		grid-row-start: 2;
	}
	.wrap_items {
		align-items: center;
		.social_icons {
			position: relative;
			width: 50px;
			@media ${device.desktop} {
				width: 100%;
			}
			&:after {
				position: absolute;
				right: 0px;
				top: 50%;
				transform: translate(-50%, -50%);
				width: 2px;
				height: 400px;
				background-color: var(--brand_blue);
				content: ' ';
				display: block;
				@media ${device.desktop} {
					right: auto;
					top: auto;
					bottom: 0;
					left: 50%;
					height: 2px;
					width: 50%;
				}
				@media ${device.mobile} {
					width: 80%;
				}
			}
			ul {
				list-style-type: none;
				padding-left: 0;
				@media ${device.desktop} {
					display: grid;
					grid-template-columns: 1fr 1fr 1fr;
					text-align: center;
					width: 200px;
					height: 43px;
					margin: 0 auto;
				}
				@media ${device.mobile} {
					width: 50%;
				}
				li {
					margin-bottom: 15px;
					svg {
						width: 25px;
						height: 25px;
					}
				}
			}
		}
	}
`;

const SocialsHome = () => (
	<SocialsHomeWrap>
		<ScrollAnimation animateIn="fadeInLeft" animateOnce>
			<div className="wrap_items">
				<div className="social_icons">
					<ul>
						<li className="facebook">
							<a target="_blanc" href="https://facebook.com">
								<IconFacebook />
							</a>
						</li>
						<li className="instagram">
							<a target="_blanc" href="https://instagram.com">
								<IconInstagram />
							</a>
						</li>
						<li className="twitter">
							<a target="_blanc" href="https://twitter.com">
								<IconTwitter />
							</a>
						</li>
					</ul>
				</div>
			</div>
		</ScrollAnimation>
	</SocialsHomeWrap>
);

export default SocialsHome;
