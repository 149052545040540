/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import Img from 'gatsby-image';
import ScrollAnimation from 'react-animate-on-scroll';
import { device } from '../lib/device';

const GalleryHomeWrap = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	gap: 40px;
	@media ${device.desktopHhd} {
		margin-left: 50px;
	}
	@media ${device.desktop} {
		margin-left: 0px;
		margin-top: 50px;
	}
	@media ${device.tablet} {
		grid-template-columns: 1fr 1fr;
		margin-top: 60px;
		grid-auto-flow: dense;
	}
	.image_item {
		position: relative;
		display: block;
		text-decoration: none;
		color: black;
		transition: all 0.3s ease;
		&:hover,
		&.is_active {
			transition: all 0.4s ease;
			transform: scale(1.1);
			@media ${device.desktop} {
				transform: scale(1);
			}
			h4 {
				color: var(--brand_blue);
				border-bottom: 1px solid var(--brand_blue);
			}
			.image_wrap {
				transition: all 0.3s ease;
				img {
					filter: grayscale(0%);
				}
			}
		}

		.image_wrap {
			overflow: hidden;
			pointer-events: none;
			margin-bottom: 20px;
			img {
				transition: all 0.4s ease;
				/* filter: grayscale(100%); */
				pointer-events: none;
				@media ${device.desktop} {
					filter: grayscale(0%);
				}
			}
		}
		h4 {
			font-family: var(--font_harmoniasanspro_light);
			font-weight: 100;
			font-size: 25px;
			margin: 0;
			color: var(--brand_black);
			padding-bottom: 20px;
			border-bottom: 1px solid var(--brand_black);
			pointer-events: none;
			@media ${device.tablet} {
				font-size: 20px;
				color: var(--brand_blue);
				border-bottom: 1px solid var(--brand_blue);
				padding: 10px 0;
				text-align: center;
			}
		}
	}
	.mobile_rights {
		display: none;
		position: relative;
		@media ${device.desktop} {
			display: block;
			grid-column: span 4;
			padding-top: 40px;
		}
		@media ${device.tablet} {
			grid-column: span 2;
		}
		p {
			font-family: var(--font_harmoniasanspro_light);
			font-weight: 100;
			font-size: 20px;
			margin: 0px;
			text-align: center;
			color: var(--brand_black);
			position: absolute;
			bottom: -50px;
			left: 50%;

			transform: translate(-50%, -50%);
			@media ${device.desktop} {
				bottom: -40px;
			}
			@media ${device.tablet} {
				bottom: 0px;
				width: 100%;
			}
			@media ${device.mobile} {
				font-size: 18px;
			}
			span {
				width: 100%;
				font-size: 12px;
				@media ${device.desktop} {
					padding-bottom: 40px;
				}
			}
		}
	}
`;
function GalleryHome({ links }) {
	let hoverElements;
	useEffect(
		(e) => {
			hoverElements = document.querySelectorAll('.image_item');
			hoverElements[2].classList.add('is_active');
		},
		[hoverElements]
	);

	function handleHovers(e) {
		hoverElements = document.querySelectorAll('.image_item');
		hoverElements.forEach((hoverElement) => {
			hoverElement.classList.remove('is_active');
		});
		e.target.classList.add('is_active');
	}
	return (
		<GalleryHomeWrap>
			{links.map((link, i) => (
				<ScrollAnimation animateIn="fadeInUp" delay={i} key={`id_${i}`} animateOnce>
					<Link className="image_item" to={link.linkUrl} onMouseOver={handleHovers}>
						<div className="image_wrap">
							<Img fluid={link.linkImage.localFile.childImageSharp.fluid} alt="" objectFit="cover" />
						</div>
						<h4>{link.linkTitle}</h4>
					</Link>
				</ScrollAnimation>
			))}
			<div className="mobile_rights">
				<p>
					<span>Marianne Hesle 2020. All Rights Reserved</span>
				</p>
			</div>
		</GalleryHomeWrap>
	);
}

export default GalleryHome;
