/* eslint-disable react/prop-types */
import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import ScrollAnimation from 'react-animate-on-scroll';
import { device } from '../lib/device';

const AboutHomeWrap = styled.div`
	position: relative;
	max-width: 100%;
	@media ${device.desktop} {
		margin-right: 0;
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 40px;
	}
	@media ${device.tablet} {
		grid-template-columns: 1fr;
		gap: 0px;
		grid-row-start: 1;
	}
	.image_wrapper {
		margin-bottom: 30px;
	}
	h2 {
		font-family: var(--font_harmoniasanspro_light);
		font-size: 60px;
		font-weight: 100;
		margin: 0px;
		color: var(--brand_black);
		@media ${device.desktopFhd} {
			font-size: 40px;
		}
		@media ${device.mobile} {
			font-size: 40px;
		}
	}
	h3 {
		font-family: var(--font_harmoniasanspro_light);
		font-weight: 100;
		font-size: 25px;
		color: var(--brand_blue);
		margin-bottom: 25px;
		@media ${device.desktop} {
			margin-bottom: 10px;
		}
		@media ${device.tablet} {
			font-size: 20px;
		}
	}
	p {
		font-family: var(--font_harmoniasanspro_light);
		font-weight: 100;
		font-size: 20px;
		margin-bottom: 30px;
		margin-top: 20px;
		color: var(--brand_black);
		position: relative;
		@media ${device.mobile} {
			font-size: 18px;
		}
		&.copyrights {
			margin: 0;
			width: 100%;
		}
		span {
			font-size: 12px;
			transform: translate(-50%, 50%);
			@media ${device.desktop} {
				display: none;
			}
		}
	}
	a {
		font-family: var(--font_harmoniasanspro_light);
		border: 1px solid var(--brand_blue);
		padding: 5px 10px;
		text-decoration: none;
		color: var(--brand_blue);
		margin-bottom: 40px;
		display: inline-block;
		transition: all 0.4s ease;
		&:hover {
			background-color: var(--brand_blue);
			color: white;
		}
	}
`;
const AboutHome = ({ heroImage, title, subtitle, description }) => (
	<AboutHomeWrap>
		<ScrollAnimation animateIn="fadeInUp" animateOnce>
			<div className="image_wrapper">
				<Img fluid={heroImage.localFile.childImageSharp.fluid} />
			</div>
			<div>
				<h3>{subtitle}</h3>
				<h2>{title}</h2>
				<p>{description}</p>
				<Link className="button" to="/">
					Read more
				</Link>
			</div>
			<p className="copyrights">
				<span>Marianne Hesle 2020. All Rights Reserved</span>
			</p>
		</ScrollAnimation>
	</AboutHomeWrap>
);

export default AboutHome;
