import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

const HomeData = () => {
	const data = useStaticQuery(graphql`
		{
			wpPage(databaseId: { eq: 166 }) {
				title
				acf {
					description
					subtitle
					mainTitle
					heroImage {
						localFile {
							childImageSharp {
								fixed {
									...GatsbyImageSharpFixed
								}
								fluid {
									...GatsbyImageSharpFluid
								}
							}
						}
					}
					galleryLinks {
						linkTitle
						linkUrl
						linkImage {
							localFile {
								childImageSharp {
									fixed {
										...GatsbyImageSharpFixed
									}
									fluid {
										...GatsbyImageSharpFluid
									}
								}
							}
						}
					}
				}
				seo {
					canonical
					cornerstone
					focuskw
					metaDesc
					metaKeywords
					metaRobotsNofollow
					metaRobotsNoindex
					opengraphAuthor
					opengraphDescription
					opengraphModifiedTime
					opengraphPublishedTime
					opengraphPublisher
					opengraphSiteName
					opengraphTitle
					opengraphType
					opengraphUrl
					title
					twitterDescription
					twitterTitle
					twitterImage {
						localFile {
							url
						}
					}
					opengraphImage {
						localFile {
							url
						}
					}
				}
			}
		}
	`);
	return data.wpPage;
};

export default HomeData;
